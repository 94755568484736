import React, { useEffect, useContext, useState } from "react";

import { ModalLayout } from "@layouts";
import { EModalTypes } from "@constants/modal";
import { ModalContext } from "@contexts";
import { InfoBox } from "@ui";
import { ConfirmCodeSvg, InfoSvg } from "@assets/svg";
import "./confirmcode.scss";
import { axiosInstance } from "@utils";
import { BOOKING_ROUTE } from "@constants/routes";
import { useAppDispatch } from "@hooks";
import { setUser } from "@store/user/reducer";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";
import VerificationInput from "react-verification-input";

const cookies = new Cookies();

const ConfirmCodeModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [seconds, setSeconds] = useState<number>(60);
  const [showError, setShowError] = useState<boolean>(false);
  const { email, two_step_token } = modalContext?.data;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resendCode = async (): Promise<void> => {
    if (loading) return;

    setSeconds(60);
    setLoading(true);
    try {
      const resp = await axiosInstance.post(`agents/resend-verify-code`, {
        email,
        two_step_token
      });
      toast.success(resp.data.message);
      setShowError(false);
      setLoading(false);
    } catch (error) {
      setShowError(true);
      setLoading(false);
    }
  };

  const onValidate = async (): Promise<void> => {
    setLoading(true);
    try {
      const resp = await axiosInstance.post(`agents/verify-code`, {
        email,
        verify_code: code,
        device_name: "agent test device"
      });

      if (resp.status === 200 && resp.data.status === "success") {
        dispatch(setUser(resp.data.user));
        const d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
        navigate(`/${BOOKING_ROUTE}`);

        cookies.set("access_token", resp.data.token, {
          path: "/",
          expires: d,
          ...(process.env.REACT_APP_STAGE !== "development" && {
            domain: ".gpu.travel"
          })
        });

        modalContext?.handleClose();
      }
    } catch (error) {
      setCode("");
      setShowError(true);
      setLoading(false);
    }
  };

  const formatSeconds = (seconds: number): string => {
    const format = (val: number): string => `0${Math.floor(val)}`.slice(-2);
    const minutes = (seconds % 3600) / 60;

    return [minutes, seconds % 60].map(format).join(":");
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      if (seconds) {
        setSeconds((prev) => prev - 1);
      }
    }, 1000);
    return () => clearTimeout(interval);
  }, [seconds]);

  useEffect(() => {
    if (code.length === 6) {
      onValidate();
    }
  }, [code]);

  return (
    <ModalLayout
      className={styles.code}
      type={EModalTypes.CONFIRM_CODE}
      close={false}
    >
      <div className={styles.agree__wrapper}>
        <ConfirmCodeSvg />
        <h2>Confirmation code</h2>
        <p>Please enter the 6-digit code from Email to login</p>

        {showError && (
          <InfoBox
            className={styles.code__info}
            color="warning"
            icon={InfoSvg}
            text=" The number that you've entered doesn't match your code. Please try
            again."
          />
        )}

        <VerificationInput
          length={6}
          placeholder=""
          onChange={(value) => {
            setCode(value);
          }}
          classNames={{
            container: "confirm-container",
            character: "character",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
            characterFilled: "character--filled"
          }}
        />

        <p
          className={seconds === 0 ? styles.active : ""}
          onClick={() => {
            if (seconds === 0) {
              resendCode();
            }
          }}
        >
          {seconds ? formatSeconds(seconds) : ""} Resend code
        </p>
      </div>
    </ModalLayout>
  );
};

export default ConfirmCodeModal;
