import React, { useEffect, useState } from "react";

import {
  Booking,
  BookingSkeleton,
  EmptyBooking,
  Header
} from "@modules/History/components";
import { InfiniteScroll, MainLayout } from "@layouts";
import { useInfiniteFetchData, useQuery } from "@hooks";
import { EBookingType } from "@constants/history";
import {
  HistoryLogsModal,
  OrderInfoModal,
  Autorepeat
} from "@getpickedupgpu/gpu2.shared";

import type { IDetailedOrder, IInvoiceFilter, IOrder } from "@interfaces";
import type { ISelectListItem } from "@ui/ui.types";
import { WelcomeBack } from "@components";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";
import { DEFAULT_URL, config } from "@constants/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BOOKING_ROUTE, HISTORY_ROUTE, PAYMENT_ROUTE } from "@constants/routes";

const types = [
  { label: "Upcoming", value: EBookingType.UPCOMING },
  { label: "Suspended", value: EBookingType.SUSPENDED },
  { label: "Complete", value: EBookingType.DONE },
  { label: "Canceled", value: EBookingType.CANCELED },
  { label: "Autorepeat", value: EBookingType.AUTOREPEAT },
  { label: "All" }
];

const AllBookings = (): JSX.Element => {
  const user = useSelector(selectUser);
  const [openHistory, setOpenHistory] = useState(false);
  const { status } = useQuery();
  const navigate = useNavigate();

  const [selectedOrder, setSelectedOrder] = useState<IOrder>();
  const [booking, setBooking] = useState<IOrder>();

  const closeOrderInfoModal = (): void => {
    setSelectedOrder(undefined);
  };

  const [active, setActive] = useState<ISelectListItem>(
    status ? types.find((el) => el.value === status) ?? types[0] : types[0]
  );
  const [filter, setFilter] = useState<IInvoiceFilter>({
    dateFrom: undefined,
    dateTo: undefined,
    amountFrom: "",
    amountTo: "",
    customer: undefined
  });

  const { list, hasMore, nextPage, loading, setData, setLoading, clearData } =
    useInfiniteFetchData<IOrder>(
      `orders?detail=1&${
        active.value === undefined
          ? ""
          : `status=${active.value !== "autorepeat" ? active.value : ""}`
      }${filter.customer !== undefined ? `&cid=${filter.customer.cid}` : ""}${
        filter.bookingNumber ? `&oid=${filter.bookingNumber}` : ""
      }${filter.dateFrom !== undefined ? `&fromDate=${filter.dateFrom}` : ""}${
        filter.dateTo !== undefined ? `&toDate=${filter.dateTo}` : ""
      }${filter.refno ? `&by_booking_refno=${filter.refno}` : ""}`,
      10
    );

  const removeOrderById = (id: number): void => {
    setData((prev) => prev.filter((el) => el.oid !== id));
  };

  const initLoading = loading && list.length === 0;

  useEffect(() => {
    clearData();
    setLoading(true);
    setData([]);
  }, [active, filter]);
  return (
    <>
      <MainLayout extra={<WelcomeBack />}>
        <Header
          active={active}
          setActive={setActive}
          types={types}
          setFilter={setFilter}
          filter={filter}
        />
        {!loading && list.length === 0 && active.value !== "autorepeat" ? (
          <EmptyBooking status={active.value as EBookingType} />
        ) : initLoading && active.value !== "autorepeat" ? (
          <BookingSkeleton />
        ) : active.value === "autorepeat" ? (
          <Autorepeat
            role="agent"
            id={user?.id}
            user={user}
            config={{
              ...config,
              default_url: DEFAULT_URL
            }}
            filter={filter}
            defaultUrl={DEFAULT_URL}
          />
        ) : (
          <InfiniteScroll
            length={list.length}
            hasMore={hasMore}
            getData={nextPage}
            scrollableTarget="history_list"
          >
            {list.map((el, index) => (
              <Booking
                booking={el}
                key={index}
                setSelectedOrder={setSelectedOrder}
                setBooking={setBooking}
                setOpenHistory={setOpenHistory}
              />
            ))}
          </InfiniteScroll>
        )}
      </MainLayout>
      {selectedOrder ? (
        <OrderInfoModal
          isOpen={!!selectedOrder}
          onClose={closeOrderInfoModal}
          user={user}
          order={selectedOrder}
          defaultUrl={DEFAULT_URL}
          removeOrderById={removeOrderById}
          onEdit={(order: IDetailedOrder) => {
            if (order.read_only_message) {
              toast.info(order.read_only_message);
              return;
            }
            navigate(`/${HISTORY_ROUTE}/${BOOKING_ROUTE}/${order.oid}`, {
              state: order
            });
          }}
          onPay={(oid: number) => {
            navigate(`/${PAYMENT_ROUTE}/${oid}`);
          }}
        />
      ) : null}
      {openHistory && (
        <HistoryLogsModal
          booking={booking?.oid}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          date={booking.tm_as_string}
          status={booking?.status}
          isOpen={openHistory}
          config={{
            ...config,
            default_url: DEFAULT_URL
          }}
          onClose={() => setOpenHistory(false)}
        />
      )}
    </>
  );
};

export default AllBookings;
